<template>
	<div class="data-content">
		<div class="data-list-box">
			<el-table
				:data="dataList"
				:row-class-name="tableRowClassName"
				:header-cell-style="{color:'#444'}"
				style="width: 100%">
				<el-table-column
					label="NO."
					width="60">
					<template slot-scope="scope">
						<span class="no">{{scope.$index+1}}</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="courseCode"
					show-overflow-tooltip
					label="课程编号">
				</el-table-column>
				<el-table-column
					prop="courseName"
					show-overflow-tooltip
					label="课程名称">
				</el-table-column>
				<el-table-column
					prop="examinTime"
					show-overflow-tooltip
					label="限制时间">
					<template slot-scope="scope">
						{{scope.row.examinTime}}分钟
					</template>
				</el-table-column>
				<el-table-column
					prop="endTime"
					show-overflow-tooltip
					label="测试有效期限">
					<template slot-scope="scope">
						<span>{{scope.row.endTime.replace(new RegExp('-','g'),'/')}}</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="examinTime"
					show-overflow-tooltip
					label="状态">
					<template slot-scope="scope">
						<span v-if="scope.row.status == 1">进行中</span>
						<span v-if="scope.row.status == 2">未通过</span>
						<span v-if="scope.row.status == 3">通过</span>
						<span v-if="scope.row.status == 4">已过期</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="pubBy"
					show-overflow-tooltip
					label="发布者">
				</el-table-column>
				<el-table-column
					label="操作"
					width="130">
					<template slot-scope="scope">
						<button v-if="scope.row.status != 4" class="btn-blue" @click="handleExam(scope.row)">开始考试</button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-wrapper pa25">
				<Pagination
					:page.sync="pageNo"
					:pageSize.sync="pageSize"
					:total="total"
					@sizeChange="handleSizeChange"
					@currentChange="handleCurrentChange">
				</Pagination>
			</div>
		</div>
	</div>
</template>
<script>
import Pagination from '@/components/Pagination'
export default {
	components: {
		Pagination
	},
	data() {
		return {
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			pageNo: 1,
			pageSize: 20,
			total: 0,
			dataList: [],
			visible: false
		}
	},
	created() {
		this.getOnlineList();
	},
	methods: {
		// NO.隔行变色
		tableRowClassName({row,rowIndex}) {
			if(rowIndex%2 == 1) {
				return 'blue'
			}else {
				return 'yellow'
			}
		},
		// 获取列表数据
		getOnlineList() {
			let that = this;
			that.$request.post(
				"getExamCourseList",
				true,
				{
					pageNo: that.pageNo,
					pageSize: that.pageSize
				},
				function (r) {
					if (r.code == "0") {
						that.dataList = r.data.list;
						that.total = r.data.totalCount;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		handleExam(dt) {
			this.$router.push({
				name: 'examLearn',
				query: {
					courseId: dt.courseId,
					id: dt.id
				}
			})
		},
		handleSizeChange() {
			this.getOnlineList();
		},
		handleCurrentChange() {
			this.getOnlineList();
		},
		handleSearch() {
			this.pageNo = 1;
			this.getOnlineList();
		}
	}
}
</script>
<style lang="scss" scoped>
.block{
	display: block;
}
.data-content{
	.top-box{
		height: 32px;
		margin-bottom: 20px;
	}
	.add-btn{
		display: inline-block;
		width: 66px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: #117FFC;
		border-radius: 4px;
		color: #fff;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.import-btn{
		display: inline-block;
		width: 66px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.top-right-box{
		float: right;
		.label{
			font-size: 15px;
			margin-right: 5px;
		}
		.search-input{
			width: 138px;
			margin: 0 10px;
		}
		.search-btn{
			width: 32px;
    		vertical-align: middle;
			cursor: pointer;
		}
		.refresh-btn{
			width: 32px;
			height: 32px;
			border: none;
			color: #117FFC;
			padding: 0;
			background-color: rgba(17, 127, 252, 0.2);
			font-weight: bold;
			font-size: 22px;
			vertical-align: bottom;
			margin-left: 5px;
		}
	}
	.btn-blue{
		height: 32px;
		border: none;
		color: #fff;
		padding: 0 15px;
		background-color: #117FFC;
		cursor: pointer;
		margin-right: 10px;
		border-radius: 5px;
	}
	.btn-red{
		font-size: 12px;
		font-weight: bold;
		color: #E9515E;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
}
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.error-list{
	height: 50vh;
    box-sizing: border-box;
    padding: 20px;
    overflow: auto;
	li{
		list-style: none;
	}
}
</style>
<style>
	.el-message .user-message{
		margin: 15px;
	}
</style>
